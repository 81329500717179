<script>
    import "bootstrap/dist/css/bootstrap.min.css";
    import "bootstrap/dist/js/bootstrap.bundle.min.js";

    export default {
        name: 'App'
    }
</script>

<template>
    <div class="container">        
        <router-view></router-view>
    </div>
</template>
